@import url('https://fonts.googleapis.com/css2?family=Spinnaker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
/*
@font-face {
    font-family: 'Reklame Script';
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ReklameScript-Medium.eot');
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ReklameScript-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ReklameScript-Medium.woff2') format('woff2'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ReklameScript-Medium.woff') format('woff'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ReklameScript-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Futura LT';
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/FuturaLT-Book.eot');
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/FuturaLT-Book.eot?#iefix') format('embedded-opentype'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/FuturaLT-Book.woff2') format('woff2'), 
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/FuturaLT-Book.woff') format('woff'), 
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/FuturaLT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; 
    font-display: swap;
}
@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ProximaNova-Regular.eot');
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ProximaNova-Regular.woff2') format('woff2'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ProximaNova-Regular.woff') format('woff'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/ProximaNova-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Trend Sans Four';
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/TrendSansFour.eot');
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/TrendSansFour.eot?#iefix') format('embedded-opentype'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/TrendSansFour.woff2') format('woff2'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/TrendSansFour.woff') format('woff'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/TrendSansFour.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} 
@font-face {
    font-family: 'LuloCleanW01-OneBold';
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/LuloCleanW01-OneBold.eot');
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/LuloCleanW01-OneBold.eot?#iefix') format('embedded-opentype'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/LuloCleanW01-OneBold.woff2') format('woff2'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/LuloCleanW01-OneBold.woff') format('woff'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/LuloCleanW01-OneBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Pro';
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/AvenirLTPro-Light.eot');
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/AvenirLTPro-Light.eot?#iefix') format('embedded-opentype'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/AvenirLTPro-Light.woff2') format('woff2'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/AvenirLTPro-Light.woff') format('woff'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/AvenirLTPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/Helvetica-Bold.eot');
    src: url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/Helvetica-Bold.woff2') format('woff2'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/Helvetica-Bold.woff') format('woff'),
        url('https://yolomeal-fonts.s3-us-west-2.amazonaws.com/Helvetica-Bold.ttf') format('truetype'); 
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
*/

@font-face {
  font-family: 'Reklame Script';
  src: url('./fonts/ReklameScript-Medium.eot');
  src:
    url('./fonts/ReklameScript-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/ReklameScript-Medium.woff2') format('woff2'),
    url('./fonts/ReklameScript-Medium.woff') format('woff'),
    url('./fonts/ReklameScript-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/FuturaLT-Book.eot');
  src:
    url('./fonts/FuturaLT-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/FuturaLT-Book.woff2') format('woff2'),
    url('./fonts/FuturaLT-Book.woff') format('woff'),
    url('./fonts/FuturaLT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('./fonts/ProximaNova-Regular.eot');
  src:
    url('./fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('./fonts/ProximaNova-Regular.woff') format('woff'),
    url('./fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Trend Sans Four';
  src: url('./fonts/TrendSansFour.eot');
  src:
    url('./fonts/TrendSansFour.eot?#iefix') format('embedded-opentype'),
    url('./fonts/TrendSansFour.woff2') format('woff2'),
    url('./fonts/TrendSansFour.woff') format('woff'),
    url('./fonts/TrendSansFour.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LuloCleanW01-OneBold';
  src: url('./fonts/LuloCleanW01-OneBold.eot');
  src:
    url('./fonts/LuloCleanW01-OneBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/LuloCleanW01-OneBold.woff2') format('woff2'),
    url('./fonts/LuloCleanW01-OneBold.woff') format('woff'),
    url('./fonts/LuloCleanW01-OneBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Pro';
  src: url('./fonts/AvenirLTPro-Light.eot');
  src:
    url('./fonts/AvenirLTPro-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/AvenirLTPro-Light.woff2') format('woff2'),
    url('./fonts/AvenirLTPro-Light.woff') format('woff'),
    url('./fonts/AvenirLTPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./fonts/Helvetica-Bold.eot');
  src:
    url('./fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Helvetica-Bold.woff2') format('woff2'),
    url('./fonts/Helvetica-Bold.woff') format('woff'),
    url('./fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.helvetica {
  font-family: 'Helvetica';
}
.futuralt-book {
  font-family: 'Futura LT' !important;
}
.reklame-script {
  font-family: 'Reklame Script' !important;
}
.trend-sans {
  font-family: 'Trend Sans Four' !important;
}
.lulo-bold {
  font-family: 'LuloCleanW01-OneBold' !important;
}
.avenir {
  font-family: 'Avenir LT Pro' !important;
}

/* html, body { font-family: 'Proxima Nova Rg'; font-weight: 300;line-height: 1.5;font-size:18px; }  */
html,
body {
  font-family: 'Spinnaker', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
}
:focus {
  outline: none !important;
}
*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a:not(.underline) {
  text-decoration: none;
}
.white,
.white * {
  color: #fff !important;
}
.black,
.black * {
  color: #000;
}
/* header .active a {text-decoration: underline;} */
header a.active {
  text-decoration: underline;
}
a {
  transition: all 0.2s ease-in-out;
}
h1,
h2,
h3,
.food-mobile-nav {
  font-family: 'Poppins';
  font-weight: 500;
}
li {
  list-style: none;
}
h1 {
  font-size: 66px;
}
h2 {
  font-size: 54px;
}
h1 a {
  display: inline-block;
}
a.bttn1 {
  background: #ff8c62;
  display: block;
  text-align: center;
  font-family: 'Poppins' !important;
  color: #fff;
  font-size: 56px;
  padding: 5px;
}
a.bttn1:hover {
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 20%);
}
.f-15 {
  font-size: 15px;
  line-height: 1.2;
}
.f-16 {
  font-size: 16px;
  line-height: 1.2;
}
.f-18 {
  font-size: 18px;
  line-height: 1.2;
}
.f-20 {
  font-size: 20px;
  line-height: 1.2;
}
.f-22 {
  font-size: 22px;
  line-height: 1.2;
}
.f-24 {
  font-size: 24px;
  line-height: 1.2;
}
.f-26 {
  font-size: 26px;
  line-height: 1.2;
}
.f-28 {
  font-size: 28px;
  line-height: 1.2;
}
.f-38 {
  font-size: 38px;
  line-height: 1.2;
}
.f-44 {
  font-size: 44px;
  line-height: 1.2;
}
.f-120 {
  font-size: 120px;
  line-height: 1.2;
}
h2.small {
  font-size: 34px;
}
h1.trend-sans {
  font-size: 60px;
}
p {
  margin-bottom: 15px;
}
.d-none {
  display: none;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}
.pt-4 {
  padding-top: 40px;
}
.pb-2 {
  padding-bottom: 20px;
}
.pb-3 {
  padding-bottom: 30px;
}
.pb-4 {
  padding-bottom: 40px;
}
.bg-white {
  background-color: #fff;
}
.full-height {
  min-height: 100vh;
}
.justify-content-center {
  justify-content: center;
}
.uppercase {
  text-transform: uppercase;
}
.d-flex {
  display: flex;
}
.d-flex-buttons {
  display: flex;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-space-between {
  justify-content: space-between;
}
.flex-col{
  flex-direction: column;
}
.w-50{
  width: 50vw;
}
.main-bg {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.eat-bg {
  background: #f9c5b4;
}
.small-container {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 15px;
}
.img-fluid {
  max-width: 100%;
  height: 100%;
  display: block;
}
.food-row h2,
.food-row h1 {
  text-transform: uppercase;
  line-height: 1;
}
.food-box {
  padding: 5px;
}
.food-box h2 {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0%, -50%);
  /* opacity: 0; */
  transition: all 0.4s ease-in-out 0s;
  font-size: 36px;
}
.food-box a {
  position: relative;
  width: 100%;
  display: block;
  transition: all 0.4s ease-in-out 0s;
}
.food-box a:hover {
  transform: scaleX(1.12) scaleY(1.12) rotate(0deg);
}
.food-box a:hover h2 {
  opacity: 1;
}
.col-1.food-col,
.col-3.food-col {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.col-2.food-col {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0 8px;
  margin-bottom: 80px;
}
.food-box img {
  width: 100%;
  object-fit: cover;
}
.col-1 .food-box img,
.col-3 .food-box img {
  min-height: 224px;
  height: 25vh;
}
.col-2.food-col .food-box img {
  min-height: 280px;
  height: 27vh;
}
.food-col {
  position: relative;
}
.col-1.food-col h1,
.col-3.food-col h1 {
  width: 100%;
  position: absolute;
  bottom: -16vh;
}

.food-mobile-nav {
  padding-bottom: 50px;
}
.food-mobile-nav a {
  font-size: 36px;
  margin: 0 16px;
  text-transform: uppercase;
}
.food-mobile-nav nav {
  /* background: rgb(0 0 0 / 50%); */
  padding: 10px 0;
  /* flex-wrap: wrap; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* DRINK PAGE */

.drink-bg {
  background: #f9c5b4;
}
.drink-main {
  max-width: 1000px;
}
.drink-main .col-2.food-col {
  max-width: 44%;
  padding: 0 30px;
}
.drink-main .col-1 .food-box img,
.drink-main .col-3 .food-box img {
  min-height: 385px;
  height: 35vh;
}
.drink-main .col-2.food-col .food-box:nth-child(1) img {
  min-height: 240px;
  object-position: bottom center;
  height: 30vh;
}
.drink-main .col-2.food-col .food-box:nth-child(2) img {
  min-height: 200px;
  height: 24vh;
}
.drink-main .col-2.food-col .food-box:nth-child(3) img {
  min-height: 220px;
  height: 25vh;
}

/* HEDER */
.container {
  max-width: 950px;
  margin: 0 auto;
  padding: 0 15px;
}
.header {
  background: url(./images/header-bg.jpg) center center;
  background-size: cover;
  font-size: 16px;
  padding: 13px 0;
  letter-spacing: 0.2px;
}
.header ul {
  justify-content: space-around;
  z-index: 100;
}
.header a:hover {
  text-decoration: underline;
}
.menu-toggle {
  color: #fff;
  font-size: 30px;
  display: none;
  text-align: right;
  padding: 0 15px;
}
.about-content {
  padding: 50px;
}
.terms-content {
  margin: 0 50px;
}

/* TERMS */
.terms-box {
  font-size: 15px;
  padding: 30px 20px;
}
.terms-bg,
.faq-bg {
  background-image: url(./images/terms-bg.jpg);
}
.start-bg{
  background-image: url(./images/start.jpg);
}
.policy-content li {
  list-style: disc;
}
.policy-content ul {
  padding-left: 20px;
}
.policy-content strong {
  font-weight: bold;
}
.terms-bg strong {
  font-weight: bold;
}
.bg-orange {
  background: #f9c5b4;
}

.restaurant-page ul.top-margin-10,
.mypage ul.top-margin-10 {
  margin: 10px 0;
}
.mypage-title h1 {
  letter-spacing: -2px;
  line-height: 1.5;
}
.mypage-title ul {
  letter-spacing: -1px;
}
.mypage-title li span {
  font-size: 29px;
  padding: 0 10px;
}
.mypage-title ul {
  border-top: 5px solid #000;
  border-bottom: 5px solid #000;
  padding: 7px 0 14px;
}
.page-box-main {
  padding: 60px;
}

.my-pagebox h2 {
  letter-spacing: -4px;
}
.my-pagebox,
.md-container {
  max-width: 535px;
  margin: 0 auto;
  font-family: 'Avenir LT Pro';
}
.page-table h6 {
  background: #ffcbaa;
  padding: 7px 20px 0;
  min-height: 50px;
}
.my-pagebox h2 {
  margin-bottom: 60px;
}
.page-table .left-col {
  background: #fff;
  padding: 5px 10px;
  flex: 1;
  /*min-height: 200px;*/
}
.page-table .right-col {
  background: #000;
  padding: 15px;
  flex: 0 0 175px;
  max-width: 175px;
}
.page-table span {
  display: block;
}
.page-table .name,
.page-table .food-name {
  background: #000;
  color: #fff;
  font-size: 13px;
  padding: 3px 7px;
  max-width: 250px;
  margin: 0 auto 3px;
}
.page-table .left-col p {
  font-size: 16px;
}
.page-table .no-photo {
  letter-spacing: -3px;
  font-size: 15px;
  cursor: pointer;
}
.page-table .no-photo {
  letter-spacing: -3px;
  font-size: 15px;
  cursor: pointer;
}
.page-table .votes {
  letter-spacing: -3px;
  font-size: 15px;
}
.page-table .votes {
  background: #fff;
  padding: 7px;
  font-size: 17px;
  margin: 22px 0 0;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
}
.votes-info-no-photo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
.votes-info {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .delete-bttn {font-size: 11px;background: url(./images/wood-bg.jpg) no-repeat center center;display: inline-block;padding: 5px 12px;border-radius: 100px; 
color: #000;font-weight: 600;float: right;margin: 8px 40px 0 0;     text-align: center;} */
.delete-bttn:hover {
  background-position: bottom center;
  transition: all 0.1s ease;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 25%);
}
.page-table {
  margin-bottom: 70px;
}
.food-pagebox .page-table:last-child {
  margin-bottom: 150px;
}

/* FAQ */
h3.lulo-bold {
  font-size: 28px;
  letter-spacing: -4px;
  line-height: 1.5;
}
.about-title {
  margin: 10px 0 40px;
}
.faq-content p b {
  font-weight: bold;
}
.notification-settings h3 {
  margin-bottom: 25px;
}
.notification-settings ul {
  max-width: 550px;
  margin: 0 auto;
}
.notification-settings ul li {
  margin: 10px 0;
  position: relative;
  width: 230px;
}

.tag-filter-panel {
  margin-bottom: 10px;
}

.tag-filter {
  display: inline-block;
  margin-left: 20px !important;
  text-transform: uppercase;
}

.checkbox-list input {
  display: none;
}
.checkbox-list label:before {
  content: '';
  background-color: transparent;
  border: 4px solid #000000;
  padding: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -3px;
  cursor: pointer;
}
.checkbox-list input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 10px;
  width: 7px;
  height: 15px;
  border: solid #000000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkbox-list label {
  font-weight: 600;
  cursor: pointer;
  margin-left: 40px;
}
.change-identity a {
  border: 2px solid #000;
  border-width: 2px 4px;
  padding: 7px 10px;
}
.start-row {
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px 0;
}
.start-icons ul {
  align-items: flex-end;
  gap: 70px;
}
.start-icons li {
  flex: 1 1 auto;
}
.yolomeal-info {
  padding: 10px 10px 20px 10px;
}
.startpage-content {
  padding: 0 30px;
}

.yolo-container {
  margin: 0 100px;
}
.add-yolo-info h6,
.add-yolo-info h4,
.add-yolo-info ul li a {
  font-family: 'LuloCleanW01-OneBold';
  padding: 14px;
  /* letter-spacing: 1px; */
  color: #000;
  font-weight: 400;
  font-family: 'Avenir LT Pro';
  cursor: pointer;
}
.add-yolo-info h4.disabled {
  color: #808080;
  cursor: default;
}
.add-yolo-info ul li a {
  display: block;
}
.add-yolo-info h6 {
  font-size: 14px;
}
.add-yolo-info li {
  margin: 22px 0;
}
.yolo-container h2 {
  font-size: 38px;
}
.tell-us p {
  margin: 0;
  padding: 60px 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}
.tell-us small {
  background: #fff;
  padding: 8px;
  display: block;
  width: 50px;
  margin: 1px 0 0 auto;
}
.add-photo {
  background: #000;
  letter-spacing: -3px;
  padding: 0;
  position: relative;
  width: 100%;
  height: 250px;
}
.save-yolo a {
  background: #fff;
  display: block;
  color: #000;
  font-weight: 600;
  padding: 12px 26px;
}

.add-photo .dropzone {
  height: 100%;
  position: relative;
  text-align: center;
}

.add-photo .dropzone {
  padding: 100px;
}

.foods-infoboxs .left-col {
  padding: 0;
}
.foods-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
}
.foods-infoboxs .page-table .left-col p,
.foods-infoboxs .page-table .left-col .address {
  font-size: 15px;
  margin: 0;
  padding: 10px 20px;
}
.foods-address * {
  flex: 1 1 auto;
}
.foods-address .address {
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
}
.foods-name-review p {
  padding: 8px;
}
.foods-name-review h4 {
  background: #ffcbaa;
  padding: 4px 8px;
  font-weight: 100;
  font-size: 15px;
  letter-spacing: 1px;
}
.foods-infoboxs span.name {
  margin: -1px auto 1px;
}
.foods-infoboxs .page-table {
  margin-bottom: 10px;
}
::-webkit-input-placeholder {
  color: #000;
}
::-moz-placeholder {
  color: #000;
}
:-ms-input-placeholder {
  color: #000;
}
:-moz-placeholder {
  color: #000;
}

.foods-infoboxs .right-col {
  background: #fff;
  padding: 0px;
  flex: 0 0 200px;
  max-width: 200px;
}
.home-info h2 {
  margin: 30px 0;
}
.home-info {
  max-width: 650px;
  margin: 0 auto;
}
.home-info ul li a {
  /* font-family: 'Reklame Script'; */
  font-size: 70px;
  line-height: 1.3; /* text-decoration: underline; */
}
.home-info .eat-drink {
  padding: 40px 0 20px;
}
.tell-us textarea {
  resize: none;
  width: 100%;
  border: 0;
  text-align: center;
  padding: 10px;
  font-weight: 600;
  font-size: 17px;
}
/* .add-photo input {position: absolute;right: 0;top: 0;height: 100%;width: 100%;left: 0;opacity: 0;cursor: pointer;font-size: 0;}  */
/*.address-bg {*/
/*  background-image: url(./images/address-bg.jpg);*/
/*}*/
.map-box a {
  display: block;
  max-width: 275px;
  padding: 20px 20px 0;
  color: #000;
  text-align: center;
  margin: 35px auto;
}
.address-boxs .page-table {
  margin-bottom: 10px;
}
.notification-faq a {
  font-weight: 600;
  letter-spacing: -6px !important;
}

.map-box a.map-link {
  padding: 0;
  margin: 0;
}

.map-box .restaurant-box {
  display: block;
  max-width: 275px;
  padding: 20px 20px 1px 20px;
  color: #000;
  text-align: center;
  margin: 35px auto;
  border-radius: 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2);
}

.notification-faq {
  margin-bottom: 50px;
}
.my-pagebox {
  background: #ffcbaa;
}
.page-table-header {
  padding: 10px;
}
input.field.xl-filed {
  font-size: 24px;
  padding: 24px;
  letter-spacing: 1px;
}
input.field {
  width: 100%;
  border: 0;
}
.add-yolo-info ul.category li a {
  background: #fff;
  font-weight: 600;
  padding: 8px;
  font-size: 20px;
}
.add-yolo-info ul.category {
  max-width: 550px;
  margin: 0 auto;
}
.add-yolo-info ul.category li {
  margin: 10px 0;
}
.eat-main .col-1.food-col h1,
.eat-main .col-3.food-col h1 {
  position: static;
}
/* .eat-main .col-2.food-col .food-box:nth-child(1) img {min-height: 260px;height: 36vh;} */
/* .eat-main .col-2.food-col .food-box:nth-child(3) img{min-height: 220px;height: 29vh;} */

.page-table-header h4 {
  background: #fff;
  padding: 3px 10px;
  font-size: 20px;
  font-weight: 400;
}
.page-table-header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  gap: 10px;
}
.page-table-header ul li a {
  background: #ff8c62;
  color: #000;
  font-weight: 400;
  padding: 8px 10px;
  font-size: 15px;
  display: block;
  font-family: 'LuloCleanW01-OneBold';
  letter-spacing: -4px;
}
.page-table-header ul li {
  flex: 1;
}

.page-table-header ul li.counter {
  background: #ff8c62;
  color: #000;
  font-weight: 400;
  padding: 8px 10px;
  font-size: 15px;
  display: block;
  font-family: 'LuloCleanW01-OneBold';
  letter-spacing: -4px;
}

.page-table-header p {
  background: #ff8c62;
  font-size: 13px;
  margin: 10px 0 0;
  padding: 8px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #fff;
}
.page-table-header p a {
  color: #000;
}
.cat-lists li a {
  color: #000;
  font-size: 30px;
  font-weight: 800;
  margin: 10px 0;
  display: inline-block;
  font-family: 'Helvetica';
}
.edit-delete a {
  background: #000;
  color: #fff;
  padding: 7px 10px;
  display: inline-block;
  min-width: 150px;
  border: 1px solid #000;
  text-align: center;
  font-size: 15px;
  letter-spacing: 2px;
}
.edit-delete a:hover {
  background: #fff;
  color: #000;
}
.edit-delete li {
  background: #000;
  color: #fff;
  padding: 7px 10px;
  display: inline-block;
  min-width: 150px;
  border: 1px solid #000;
  text-align: center;
  font-size: 15px;
  letter-spacing: 2px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.edit-delete li:hover {
  background: #fff;
  color: #000;
}

.change-id input {
  width: 100%;
  text-align: center;
  border: 0;
  font-size: 35px;
  padding: 40px;
  margin: 50px 0 50px;
  letter-spacing: -3px;
  font-family: 'Trend Sans Four';
}
.notification-lists ul a {
  background: #000;
  display: block;
  color: #fff;
  padding: 12px;
  margin: 12px 0;
}
.notification-lists ul {
  background: #ff8c62;
  margin: 100px 0 0 0;
  padding: 20px 20px 200px;
}
/*
.delete-yolo button.bttn {
    background: #fff;padding: 13px 22px;display: inline-block;color: #000;font-size: 19px;letter-spacing: 2px;
    border: none;
    transition: all 0.2s ease-in-out;
}
.delete-yolo button.bttn:hover {background: #000;color: #fff;}
*/

.page-box-main .page-table {
  padding: 0 10px 10px;
}
.about-content p {
  line-height: 1.4;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.landing-info * {
  font-weight: 400;
}
.landing-info {
  padding: 100px;
}
.landing-info h1 {
  font-size: 88px;
  line-height: 1.2;
}
.landing-info h4 {
  font-size: 50px;
}
.landing-info li a {
  font-size: 24px;
}
.enter-email h5 {
  font-size: 29px;
  background: #fff;
  color: #000;
  padding: 5px 10px;
}
.enter-email {
  max-width: 550px;
  margin: 40px auto;
}
.enter-email input {
  color: #000;
  width: 100%;
  display: block;
  margin: 10px auto;
  padding: 10px 5px;
  border: 0;
  font-size: 22px;
  text-align: center;
  max-width: 400px;
  font-family: 'Reklame Script' !important;
  letter-spacing: 0.8px;
}
.enter-email button {
  color: #000;
  font-size: 18px;
  border: 2px solid #fff;
  padding: 10px 22px;
  font-family: 'Reklame Script' !important;
  cursor: pointer;
}
.enter-email button:hover {
  background: #000;
  color: #fff;
}

.next-button {
  color: #000;
  padding: 0;
  border: none;
  background: none;
  font-size: 66px;
  font-family: 'Poppins';
  font-weight: 500;
  cursor: pointer;
}

.see-restaurant-button {
  color: #000;
  padding: 0;
  border: none;
  background: none;
  font-size: 25px;
  font-family: 'Avenir LT Pro';
  font-weight: 500;
  cursor: pointer;
}

.category-button {
  background: #fff;
  color: #000;
  display: block;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Avenir LT Pro';
  letter-spacing: 1px;
  padding: 8px;
  cursor: pointer;
}

.category-button.disabled {
  color: #808080;
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: rgba(255,255,255,0.9); */
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  z-index: 9999;
  text-align: center;
}

.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background-color: rgba(255,255,255,0.7); */
  z-index: 9999;
}

.yolo-image {
  width: 100%;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 49%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: white;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.upvote-button {
  cursor: pointer;
}

.hidden {
  display: none;
}

.pink-button {
  background: #ff8c62;
  color: #000;
  font-weight: 400;
  padding: 8px 10px;
  font-size: 15px;
  display: block;
  font-family: 'LuloCleanW01-OneBold';
  letter-spacing: -4px;
  cursor: pointer;
}

.page-table-header p.restaurant-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  color: #000;
}

.about-page .page-link {
  margin-bottom: 10px;
}

.about-page .page-link a {
  color: black;
}

.about-page .page-link a:not(.underline) {
  text-decoration: none;
}

.about-page .page-link a:hover {
  text-decoration: underline;
}

.facebook-button {
  display: inline-block;
}

/* .upvote-section { padding: 50px 0; } */
.upvote-row {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: center;
}

.upvote-item::-webkit-scrollbar {
  display: none;
}
.upvote-item {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.75);
}
.upvote-item img {
  width: 100%;
  display: block;
}
.upvote-col {
  width: 100%;
  max-width: 350px;
  padding: 4px 15px;
}
.upvote-item-info {
  padding: 5px 20px;
}

.upvote-item-info-description {
  padding: 5px 20px;
  @media (max-width: 570px) {
    padding: 5px 10px;
  }
}

.upvote-item-info-description p {
  @media (max-width: 570px) {
    margin: 0;
  }
}

.upvote-item-title h3 {
  font-size: 25px;
  padding: 0 6px;
  margin-bottom: 0;
}

.upvote-item-location a {
  font-size: 20px;
  color: #000;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  margin: 3px 0 8px 0;
  padding: 0 5px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.upvote-item-location p {
  margin: 0;
  @media (max-width: 500px) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.upvote-item-location {
  border-bottom: 2px solid #e0e0e0;
  margin: 0px 15px 5px 15px;
}

.item-text-description{
  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.upvote-item-location a:hover {
  text-decoration: underline;
}

.upvote-item-location img {
  max-width: 25px;
  margin-right: 10px;
}

.upvote-item-img img {
  height: 250px;
  object-fit: cover;
  width: 100%;
}

.upvote-item-img {
  height: 200px;
  position: relative;
  overflow: hidden;
  @media(max-width: 400px) {
    height: 180px;
  }
}

.upvote-item-img img {
  width: 100%;
  height: 250px;
}

.object-fit-cover{
  object-fit: cover;
}

.object-fit-contain{
  object-fit: contain;
}

.upvote-item-info p {
  margin: 0;
}
.upvote-item-info p a {
  color: #000;
  font-size: 16px;
  @media (max-width: 570px) {
    font-size: 14px;
  }
}
.upvote-count {
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  @media screen and (max-width: 500px){
    font-size: 16px;
  }
}
.upvote-item-top-content {
  text-align: center;
}
.upvote_btn {
  cursor: pointer;
}
.tap-to-upvote-btn {
  display: inline-block;
  color: #000;
  padding: 6px 24px;
  background-color: #7bf1d1;
  border-radius: 50px;
  margin: 6px 0 10px 0;
}
.tap-to-closed-btn {
  display: inline-block;
  color: #000;
  padding: 10px 24px;
  background-color: #7bf1d1;
  border-radius: 30px;
  height: 80px;
  font-size: 16px;
  margin: 6px 0 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.tap-to-thanks-btn {
  display: inline-block;
  color: #000;
  padding: 10px 24px;
  background-color: #7bf1d1;
  border-radius: 60px;
  height: 60px;
  font-size: 17px;
  margin: 6px 0 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.tap-to-upvote-btn:hover {
  background-color: #4ddbb4;
}
.morecontent span {
  display: none;
}
.morelink {
  display: inline-block;
}
.upvote-item-person-name {
  background-color: #7bf1d1;
  color: #000;
  padding: 5px 10px;
  line-height: 1.2;
  border-radius: 50px;
  margin: 2px 0 5px 0;
  display: inline-block;
  min-width: 180px;
  width: 100%;
}
.upvote-item-person-name:hover {
  background-color: #4ddbb4;
}
.inner-upvote-item {
  padding-bottom: 10px;
}

.remove-box-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.upvote-wrap {
  padding-bottom: 50px;
}
.upvote-item-btns {
  align-items: center;
  justify-content: space-between;
  margin: 5px -6px 5px -6px;
}
.upvote-item-btns .tap-to-upvote-btn {
  font-size: 18px;
  padding: 6px 5px;
  width: 100%;
  /*max-width: 135px;*/
  margin: 6px;
}
.share-list-link {
  text-align: center;
  margin-bottom: 20px;
}
.share-list-link a {
  display: inline-block;
  color: #000;
  text-transform: uppercase;
  font-size: 42px;
}
.upvote-wrap-top-cont {
  padding-top: 60px;
}
.upvote-wrap-top-cont h2 {
  margin-bottom: 15px;
}
.eat-bg {
  background: #f9c5b4;
}
.food-box.top-text h2 {
  top: 27%;
}

.shares-table td {
  padding: 5px;
}

.apple-auth-div button {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  /* min-width: 249.5px; */
  cursor: pointer;
  display: inline-block;
  line-height: 34px;
  font-size: calc(0.27548vw + 12.71074px);
  text-decoration: none;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  background-color: #000000;
  border-radius: 1px;
  border: 1px solid #000000;
  padding: 10px 24px;
  width: 100%;
}

.apple-auth-btn {
  display: flex;
  align-items: center;
}
.flex{
  display: flex;
}
.checkbox-list1{
  display: flex;
  justify-content: center;
}
.checkbox-list1 label:before{
  content: '';
  background-color: transparent;
  border: 4px solid #000000;
  padding: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -3px;
  cursor: pointer;
}
.checkbox-list1 input {
  display: none;
}
.checkbox-list1 input:disabled +label::before{
  background-color: grey;
}
.checkbox-list1 input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 10px;
  width: 7px;
  height: 15px;
  border: solid #000000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.accountSettingH1{
  font-size: 40px !important;
  @media (max-width: 800px) {
    font-size: 30px!important;
  }
  @media (max-width: 500px) {
    font-size: 20px!important;
  }
}

.account-setting-btn {
  cursor: pointer;
  display: inline-block;
  color: #000;
  font-size: 24px;
  padding: 6px 24px;
  background-color: #7bf1d1;
  border-radius: 50px;
  margin: 6px 0 10px 0;
  border: none;
}

.account-setting-btn:disabled {
  opacity: 0.6;
}
.jump-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999; /* Ensure it's above other content */
  background-color: black; /* Example button background color */
  color: white;
  border: none;
  border-radius: 10px;
  padding: 20px 40px;
  cursor: pointer;
  font-size: 20px;
}


.jump-to-top-button:hover {
  background-color: #7bf1d1;
  color: black;
}
.show-upvote{
  background-color: #7bf1d1; /* Example button background color */
  color: black;
  border: none;
  border-radius: 10px;
  padding: 20px 20px;
  cursor: pointer;
  font-size: 20px;
}

.title { font-family: 'Reklame Script'; font-weight: 500; font-size: 80px; line-height: 1.2; color: #fff; margin-bottom: 60px; }
.start-labels { font-family: 'Roboto', sans-serif; font-weight: bold;flex-wrap: wrap; }
.label-text { font-family: 'Roboto', sans-serif; font-weight: bold; padding: 10px 50px; background-color: #ec5828; font-size: 30px; line-height: 1.2; color: #fff; border-radius: 100px; margin-bottom: 60px; text-align: center; }
.btn { font-family: 'Roboto', sans-serif; font-weight: bold; background-color: #3f7c52; color: #fff; display: inline-block; margin: 0 auto; text-decoration: none; padding: 10px 50px; font-size: 30px; border-radius: 50px; margin-top: 0px; -webkit-transition: all 0.5s; transition: all 0.5s; }
.btn:hover { background-color: #ec5828; }

@media screen and (max-width: 1200px) {
  .title { font-size: 100px; }
  .label-text { font-size: 36px; }
  .btn { font-size: 48px; }
}

@media screen and (max-width: 767px) {
  .startpage-content { padding: 0 10px; }
  .title { font-size: 58px; margin-bottom: 40px; }
  .label-text { padding: 10px 20px; font-size: 20px; margin-bottom: 40px; }
  .btn { font-size: 28px; margin-top: 0; padding: 15px 40px; }
  .button-container{ display: flex; justify-content: space-between; flex-wrap: wrap;}
  .table-container {
    overflow-x: auto;
    margin: 0 auto; /* Center the table horizontally */
}

.shares-table {
    width: 100%;
    max-width: 100%;
}
  
}


.button-container{
  display: flex;
  justify-content: space-between;
}
.button-container button{
  padding: 15px 40px;
  border-radius: 5px;
  background-color: grey;
  color :black;
  width: 100%;
  margin: 0px 10px;
}
.button-getYollos button{
  padding: 15px 40px;
  border-radius: 5px;
  background-color: black;
  color :white;
  margin: 0px 10px;
  margin-bottom: 30px;
}
.select{
  padding: 5px 20px;
  border-radius: 5px;
  background-color: black;
  color :white;
}
.deleteButton{
  padding:0px 30px;
  margin:15px 0px ;
  background:red;
  color:white;
  border:none;
  border-radius: 5px;
  font-weight: bold;
}
.deleteItemButton{
  padding:10px 30px;
  margin:15px 0px ;
  background:red;
  color:white;
  border:none;
  border-radius: 5px;
  font-weight: bold;
}
.categoryItem{
  display: flex;
  /* justify-content: space-between; */
  flex-wrap:wrap;
  width: 100% ;
  align-items: stretch;
}
.categoryItemWidth{
  width: 25%;
}
.card-container {
  max-width: 1380px;
  margin: 0 auto;
  padding: 0 15px;
  @media screen and (max-width: 500px){
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .categoryItemWidth {
    width: 90%;
    padding: 10px;
  }
  .categoryItem{
    justify-content: center;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .categoryItemWidth {
    width: 50%;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.font-bold{
  /*font-weight: bold;*/
}

.circle-button {
  padding: 25px;
  background-color: #ed6a6a;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  margin-bottom: 80px;
  min-width: 210px;
  min-height: 210px;
  -webkit-transition: all .4s; transition: all .4s;
}

.circle-button:hover{
  background-color: #fff;
  color: #ed6a6a;
}

@media screen and (max-width: 767px) {
  .circle-button {
    min-width: 150px;
    min-height: 150px;
    margin-bottom: 60px;
  }
  .circle-button.circle-1 h2 {
    font-size: 58px !important;
  }
  .circle-button h2 {
    font-size: 48px !important;
  }
  .circle-button h4 {
    font-size: 28px !important;
  }
}

.text-box-wrap {
  width: 100%;
  padding: 40px 0;
  text-align: center;
}
.circle-button:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.circle-button.circle-1 h2 {
  font-size: 94px;
}
.circle-button h2 {
  max-width: 300px;
  font-size: 74px;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
}
.circle-button h4 {
  max-width: 200px;
  font-size: 42px;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
}
.banner-content {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 15px;
}
.banner-content .text-box-wrap {
  width: 100%;
  padding: 40px 0;
  text-align: center;
}
.flex-wrap {
  flex-wrap: wrap;
}

.h-100{
  height: 100%;
}
.search-icon{
  padding: 14px;
}

.bg-danger{
  background-color: red !important;
}

.danger{
  color: red !important;
}

.w-full{
  width: 100%;
}

.splashMain{
  padding : 0 10%;
  background-color:#faf9f8;
  min-height: 100vh;
  height: max-content;
}

.splash-main-sub{
  padding: 0 15%;
  @media (max-width: 500px) {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .splash-main-sub{
    align-items: center;
    padding: 0;
  }
}

.mt-5pct{
  margin-top: 10%;
}

.mb-0 {
  margin-bottom:0;
}

.font-reklame {
  font-family: 'Reklame Script';
  /*font-family: Poppins;*/
  font-size: larger;
  letter-spacing: 2px;
}

.splash-pizza{
  font-size: 60px;
  position: relative;
  color: #dddcda;
}

.splash-pizza-img {
  padding-top: 40%;
  mix-blend-mode: multiply;
}

.splash-pizza-button{
  border-radius: 16px;
  background-color: #f3b7a1;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 14px;
}
.splash-pizza-button:hover{
  background-color: #e38c61;
  transform: scale(1.1);
  font-size: 16px;

}
.pr-15pct { padding-right: 15%;
}
.font-poppins{
 font-family: "Poppins";
}
.text-14{
  font-size: 16px;
}
.ml-15{
  margin-left: 15px;
}
.mb-10{
  margin-bottom: 10px;
}

.list-disc{
  list-style: disc;
}
.underline{
  text-decoration: underline;
  color: black;
}

.splash-w-50 {
  width: 50vw;
  @media(max-width: 500px) {
    width: auto;
    padding-right: 0;
  }
  @media(max-width: 800px) {
    width: auto;
    padding-right: 10%;
  }
}

.door-dash{
  /*margin-left: 0%;*/
  mix-blend-mode: multiply;
  width: 345px;
  @media(max-width: 800px) {
    width: 357px;
  }
  @media(max-width: 400px) {
    width: 307px;
  }
}

.flex-1 {
  flex: 0 1;
}

.flex-2 {
  flex: 2px;
}

/*.pr-10pct{*/
/*  margin-right: 19%;*/
/*  padding-left: 10%;*/
/*}*/

.splashMain2{
  padding : 0 10%;
  background-color:#faf9f8;
  min-height: 100vh;
  height: max-content;
}

.splash-sub-main2{
  flex-direction: row-reverse;
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0 5%;
  }
}

.justify-content-start{
  justify-content: start;
}

.splash2-button{
  border-radius: 16px;
  background-color: #c65122;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.splash2-button:hover{
  background-color: #e38c61;
  transform: scale(1.1);
}

.heading-points ul li{
  margin-top: 15px;
}
.heading-points{
  margin-bottom: 15px;
}

.desert-Image {
  @media (min-width: 800px) {
    object-position: 0 -70px;
  }

  @media (max-width: 768px) {
    object-position: center;
  }
}

.desert-h2{
  @media (min-width: 865px) {
    top: 60% !important;
  }
}


.home-title {
  font-family: 'Reklame Script';
  font-size: 90px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 15px;
}

@media screen and (max-width: 1100px) {
  .home-title{
    font-size: 75px;
  }
}


.home-description{
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .home-description {
    font-size: 20px;
    margin: 0px;
  }
}

.home-btn{
  font-family: 'Poppins';
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
  padding: 10px 15px;
  min-width: 400px;
  background-color: #ff8c65;
  color: #fff;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-bottom: 20px;
  -webkit-transition: all .4s;
  transition: all .4s;
}


.home-right-container{
  width: 65%;
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .home-right-container {
    width: 100%;
    margin-top: 10px;
  }
}

.home-right-container ol{
  padding-left: 30px;
  margin-top: 40px;
}

.home-right-container ol li {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: bold;
  @media screen and (max-width: 500px){
    margin-top: 10px;
  }
}

@media screen and (max-width: 991px) {
  .home-right-container ol li  {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.home-left-container{
  width: 35%;
  padding-right: 40px;
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .home-left-container {
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
    padding-right: 0px;
    padding-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .home-right-container ol{
    margin-top: 10px;
  }
}


.home-btn-group {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.row {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}


.home-container{
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 30px;
}

@media screen and (max-width: 767px) {
  .home-container {
    padding: 0 20px;
  }
}

.home-btn-action{
  text-align: center;
}

@media screen and (max-width: 767px) {
  .home-btn-action .home-btn {
    font-size: 28px !important;
  }
}

.home-food-ranking {
  padding-top: 20px;
  margin-top: -40px;
}

.home-food-ranking-title {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  margin-bottom: 15px;
}

.home-food-images {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}


.home-image-item {
  width: 20%;
  padding: 10px;
}

.home-image-item img {
  height: 100%;
  min-height: 200px;
  object-fit: cover;
  width: 100%;
  display: block;
}

@media screen and (max-width: 767px) {
  .home-food-ranking-container .home-food-images .home-image-item:nth-child(4), .home-image-item:nth-child(3), .home-food-ranking .home-food-images .home-image-item:last-child {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .home-image-item  {
    width: 33.33%;
    padding: 5px;
  }
}


.justify-content-end{
  justify-content: end;
}

.light {
  font-weight: 600;
  font-size: 18px;
  font-family: 'Poppins';
}
.bold {
  font-weight: 800;
  font-size: 18px;
  color: black;
  font-family: 'Poppins';
}


.add-yolo-buttons {
  display: inline-block;
  color: #000;
  padding: 6px 24px;
  background-color: #7bf1d1;
  border-radius: 50px;
  margin: 6px 0 10px;
  font-size: 42px;
  font-family: "Poppins";
  @media (max-width: 570px) {
    font-size: 21px;
  }
}

input.field.md-filed {
  font-size: 16px;
  padding: 16px;
  letter-spacing: 1px;
  margin: 10px;
  width: 50%
}

.btn-primary{
  display: inline-block;
  color: #000;
  padding: 6px 24px;
  background-color: #7bf1d1;
  border-radius: 50px;
  font-weight: bold;
  width: 15%;
  border: none;
  cursor: pointer;
}

.disclaimer{
  font-size : 11px;
}

.disclaimerMain{
  padding : 0 10%;
  background-color:#faf9f8;
  min-height: 100vh;
  height: max-content;
}


.disclaimer-main-sub{
  padding: 0 15%;
}

.dateInputGroup > input{
  width: 150px;
  text-align: center;
}


.image-preview{
  position: relative;
}

.upload-btn-left{
  bottom: 5%;
  left: 5%;
}

.upload-btn{
  position: absolute;
  background: #7bf1d1;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  border-radius: 50px;
  min-width: 60px;
  font-size: 16px;
  border: none;
  color: black;
}
.upload-btn-right{
  bottom: 5%;
  right: 5%;
}

.upload-btn-rotate-right{
  top: 5%;
  left: 5%;
}
.upload-btn-rotate-left{
  top: 5%;
  right: 5%;
}