
@media(max-height: 825px){ 
	h1 { font-size: 50px; }
	.col-1 .food-box img, .col-3 .food-box img { min-height: 150px; }
	.col-2.food-col .food-box img { min-height: 200px; }
}


@media(max-width:1480px){ 
	h1 {font-size: 50px;}
	h2 {font-size: 42px;} 
}

@media(max-width:1370px){
	h1 {font-size: 40px;}
	h2 {font-size: 30px;}
	h2.small {font-size: 24px;}
	/* .col-2.food-col{ padding: 0 25px !important; } */
	.col-1.food-col h1, .col-3.food-col h1{ bottom: -8vh;} 
	.f-120 {font-size: 100px;}
	a.bttn1{ font-size: 46px;}
	/*.home-info ul li a{ font-size: 50px;}*/
	.home-info .eat-drink {padding: 20px 0 10px;}
}
 
@media(max-width:1200px){
	.header{font-size: 14px;}
	.terms-content {margin: 0;}
	.mypage-title h1 {font-size: 46px;}	
	.mypage-title ul {font-size: 13px;}
	.mypage-title li span {font-size: 22px;padding: 0 10px;}
	.f-120 {font-size: 90px;}
	.yolo-container {margin: 0;}
}

@media(max-width:767px){ 
	.f-26, h3.lulo-bold{font-size:22px;}   
	.f-28 { font-size: 24px;}
	.f-120 {font-size: 62px;}
	.notification-settings ul{font-size:16px;}
	.food-box {padding: 10px 15px;margin: 0;} 
	.col-2.food-col {padding: 0 !important; } 
	.food-col {max-width: 100% !important;}
	.food-row {display: block; max-width: 290px;margin: 0 auto; padding: 35px 0;}
	.sm-none{ display:none;}  

	.food-box h2, .food-box h2.small {opacity: 1;     font-size: 30px !important;}
	.food-box a:hover {transform: none;}
	
	.food-box h2.small {font-size: 30px; }
	.food-box h2.small br{display:none;}
	.eat-bg {background-position: right center;}
	.sm-block{display:block; }
	.main-bg {background-position: center center;}
	
	.menu-toggle {display: flex;justify-content: flex-end; height: 40px; width: 40px; position: absolute; right: 10px }
	.header ul:not(.hidden) + .menu-toggle{ z-index: 100; top: 3px; height: 35px}
	.menu-toggle img {max-width: 40px; margin-top: 3px;}
	.header {margin: 0 -20px; padding: 10px 0 12px 0; min-height: 60px;background: black !important;}
	.header ul {
		position: absolute;background: #000;right: 0;top: 0px;flex-direction: column;width: 100%;align-items: inherit;}
	.header ul li {padding: 12px 20px;border-bottom: 1px solid #404040;}
	.about-content {padding: 75px 0;} 
	
	.mypage-title h1 {font-size: 28px;padding: 20px 0;}
	.mypage-title ul {display: block;text-align: center;font-size: 11px;	border-width: 3px;}
	 .notification-faq {margin-bottom: 50px;}
	.page-box-main {padding: 50px 0;}
	.my-pagebox h2 {margin-bottom: 30px;}
	.policy-content h3.f-24 {font-size: 20px;} 
	.food-pagebox .page-table:last-child {margin-bottom: 120px;}
	.startpage-content, .add-yolo-info {padding: 0;}
	.start-icons ul {display: block;} 
	a.bttn1, .landing-info h4 {font-size: 32px;} 
	.start-icons ul .bttn1 {margin: 20px 0;padding: 10px;}
	.yolomeal-info {font-size: 18px;   margin-top: 15px;}
	.startpage-content h1 {margin-bottom: 15px;}
	
	.yolo-container h2 {font-size: 26px;} 
	.add-yolo-info h6 {font-size: 12px;}
	.add-yolo-info h4 {font-size: 16px;}
	.save-yolo a{ padding: 12px 15px; font-size: 14px;}
	.add-yolo-info li {margin: 15px 0;}
	
	.home-info h2 {margin: 10px 0;font-size: 34px;}
	.home-info ul li a {font-size: 40px; }
	.home-info .eat-drink {padding: 30px 0 30px;}
	.home-info ul li {margin: 8px 0;}
	

	h2.other-drinks {margin-top: 20px;}
	h2.small.coffee {margin-top: 10px;}
	input.field.xl-filed {font-size: 16px;}
	.add-yolo-info ul.category li a{ font-size: 16px;}  
	.cat-lists li a {font-size: 20px;margin: 8px 0;}
	.change-id input {padding: 20px 5px;   font-size: 22px;}
	
	.landing-info {padding: 75px 0;}
	.landing-info h1 {font-size: 60px;}
	.enter-email h5{font-size:22px;}
	input.field.md-filed { width: 80%;}
	.btn-primary {
		width: 30%;
	}
}

@media(max-width:570px){
	.d-flex-buttons{display:inline;}
	.add-photo .dropzone {
			padding: 75px;
	}
	.edit-delete li {
			display: block;
			margin-bottom: 2px;
	}

	.table-row {flex-direction: column;}
	.page-table .right-col {max-width: 100%;}
	/* .votes-info {height: 180px;} */
	.delete-bttn {margin: 15px auto 0;font-size: 13px;letter-spacing: 1px;padding: 13px 35px;float: none;display: block;max-width: 165px;border-radius: 4px;} 
	
	.foods-infoboxs .page-table .left-col p, .foods-infoboxs .page-table .left-col .address {padding: 10px;}
	.foods-infoboxs .left-col { min-height: auto;}
	.foods-infoboxs span.name {margin: 5px 8px;max-width: 100%;} 
	.foods-name-review p{font-size:13px;}
	.foods-address a, .foods-address p {font-size: 14px !important;}
	
	.page-table-header ul {display: block;}
	.page-table-header ul li {margin: 5px 0;}
	.edit-delete a{    min-width: auto;}
	
	.notification-lists ul {
    margin: 50px 0 0 0;
    padding: 10px 10px 200px;
	}
		
	.notification-lists ul a {
    padding: 10px;
    margin: 10px 0;
    font-size: 15px;
	}
	
	.no-photo-panel {
		display: none;
	}

	.upvote-item-title h3 { font-size: 16px;  padding: 5px 10px;}
	.tap-to-upvote-btn { font-size: 14px; }
	.upvote-col { padding: 4px 0px; }
	.upvote-item-img img { height: 250px; }
	.upvote-item-btns .tap-to-upvote-btn{
		font-size: 14px;
		margin: 3px;
	}
	.share-list-link a { font-size: 30px; }

	.home-food-ranking-title{
		display: none;
	}
	.home-food-images{
		display: none;
	}

	.social-icon{
		border-top: 1px solid #fff;
	}

}

@media(max-width:400px){ 
	.mypage-title h1 {font-size: 22px;}
}

@media(max-width:500px){
	.upvote-item-btns { flex-wrap: wrap; justify-content: center; }
}

@media(max-width:400px){ 
 	.upvote-item-img img { height: 190px; }
}